<script lang="ts">
	import { getContext } from 'svelte'
	import type { ActionResult } from '@sveltejs/kit'
	import type { Writable } from 'svelte/store'

	//Props
	export let name: string

	//Classes
	export let maxWidth = 'max-w-[350px]'

	//Stores
	export let fail: Writable<Extract<ActionResult, { type: 'failure' }>> = getContext('fail')
</script>

{#if $fail?.data?.[name]}
	<small class="mt-1 flex items-center gap-1 {maxWidth}">
		<img
			src="/images/icons/circle/error.svg"
			alt="error"
			class="mr-[5px] h-6 w-6 border-2 border-transparent"
		/>
		{$fail.data[name]}
	</small>
{/if}

<style>
	small {
		color: theme('colors.primary-500');
		font-size: theme('fontSize.base');
		font-weight: theme('fontWeight.normal');
	}
</style>
